import React, {FC} from 'react';
import {GatsbyImageProps} from 'gatsby-image';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import {ImageSharp} from '../common/types';

type FeatureGridProps = {
	gridItems: {
		image?: ImageSharp | string
		title: string
		text: string
	}[]
}

const FeatureGrid: FC<FeatureGridProps> = ({gridItems}) => (
	<div className="columns is-multiline">
		{gridItems.map((item, idx) => (
			<div key={idx} className="column is-6">
				<section className="section">
					<div className="has-text-centered">
						<div className="featureImage">
							<PreviewCompatibleImage imageInfo={item}/>
						</div>
					</div>
					<h2>{item.title}</h2>
					<p>{item.text}</p>
				</section>
			</div>
		))}
	</div>
);

export default FeatureGrid;
